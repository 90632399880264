body {
    background-color: #f0f4f9 !important;
  }
  
  .card {
    border-radius: 8px;
  }
  
  h2 {
    font-size: 1rem !important;
    font-weight: bold;
    color: #1a73e8;
  }
  
  /* p {
    font-size: 0.9rem;
    color: #5f6368;
  } */
  
  a {
    color: #1a73e8;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  