body {
    background-color: #f0f4f9 !important;
    font-family: Arial, sans-serif;
  }
  
  .card {
    border-radius: 8px;
  }
  
  h2 {
    font-size: 1.1rem;
    font-weight: bold;
    color: #202124;
  }
  
  /* p, .text-muted {
    font-size: 0.9rem;
    color: #5f6368;
  } */
  
  a {
    color: #1a73e8;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  button {
    background-color: #1a73e8;
    border: none;
  }
  
  button:hover {
    background-color: #135bb8;
  }
  
  input {
    border-radius: 4px;
  }
  