/* Drag and Drop zone styles */
.dropzone {
    border: 2px dashed #007bff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .dropzone.active {
    border-color: #28a745;
  }
  
  .dropzone p {
    color: #007bff;
    margin: 0;
  }
  
  .dropzone img {
    max-width: 100%;
    max-height: 200px;
    display: block;
    margin: 20px auto;
  }
  
  .dropzone-preview {
    margin-top: 20px;
    text-align: center;
   
  }
  .imagePreview{
    height: 200px;
    border-radius: 5px;
  }
  